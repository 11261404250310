export const endpoints = {
    auth: {
        user: {
            login: `/user/login/`,
            check: `/user/check/`,
        },
        otp: {
            verify: `/otp/code/verify/`,
            send: `/otp/code/send/`,
        },
        token: {
            refresh: `/user/token/refresh/`,
        }
    },
    dictionaries: {
        education_types: `/dictionaries/education-types/`,
        cities: `/dictionaries/cities/`,
        languages: `/dictionaries/languages/`,
        notification_methods: `/dictionaries/notification-methods/`,
        notification_types: `/dictionaries/notification-types/`,
        phone_codes: `/dictionaries/phone-codes/`,
        regions: `/dictionaries/regions/`,
        specifications: `/dictionaries/specifications/`,
        specification: `/dictionaries/specifications/%d/`,
        masters: `/masters/`,
        masters_filters: `/master/specification/%d/filters/`,
        mastersRecent: `/masters/recent/`,
        tasksRecent: `/tasks/recent/`,
        master_portfolio: `/masters/%d/portfolios/%d/`,
        review_tags: `/dictionaries/review-tags/`,
        feedback: `/dictionaries/feedback/`,
        knowledge_bases: `/dictionaries/knowledge-base/`,
        knowledge_base_articles: `/dictionaries/knowledge-base/%d/articles/`,
        knowledge_base_article_id: `/dictionaries/knowledge-base/%d/articles/%d/`,
        subscription_plans: `/subscription-plans/`,
    },
    master: {
        subscription: `/master/subscription/`,
        subscription_upgrade: `/master/subscription/upgrade/`,
        portfolio: {
            list: 'master/portfolios/',
            create: 'master/portfolios/create/',
            update: 'master/portfolios/%d/update/',
            detail: 'master/portfolios/%d/',
            delete: 'master/portfolios/%d/delete/',
            videoDelete: 'master/portfolios/%d/videos/%d/',
            photoDelete: 'master/portfolios/%d/photos/%d/',
        },
        task: {
            list: 'master/tasks/',
            accept: '/master/tasks/%d/accept/',
            refuse: '/master/tasks/%d/refuse/',
            submit: '/master/tasks/%d/submit/',
            complete: '/master/tasks/%d/complete/',
            reciept: '/master/tasks/%d/reciept/',
        },
        chat: {
            list: 'master/chats/',
        }
    },
    customer: {
        masters_suggest_order: `/masters/%d/suggest-order/`,
        task: {
            complete: '/customer/tasks/%d/complete/',
            callOff: '/customer/tasks/%d/call-of/',
            approve: '/customer/tasks/%d/masters/%d/approve/',
            resume: '/customer/tasks/%d/resume/',
            return: '/customer/tasks/%d/return/',
            reciept: '/customer/tasks/%d/reciept/',
        },
        chat: {
            list: 'customer/chats/',
        }
    },
    chat: {
        sendFile: '/chats/%d/file/',
        messages: '/chats/%d/messages/',
        masterDetail: '/chats/%d/master-details/',
        customerDetail: '/chats/%d/customer-details/',
    },
    evaluate: '/reviews/create/',
    task: {
        create: '/tasks/create/',
        update: '/tasks/%d/edit',
        search: '/tasks/',
        detail: '/tasks/%d/',
        response: '/tasks/%d/response/',
        BySpec: '/tasks/by-specification/',
        filter: '/tasks/specification/%d/filters/tasks/',
        profile_filter: '/tasks/specification-master',
    },
    user_management: {
        password_reset: `/user/password/reset/`,
        password_change: `/user/password/change/`,
        register: `/user/register/`,
        update: `/user/update/`,
        change_role: `/user/change-role/`,
        get_profile: `/user/profile/`,
        get_notification_settings: `/user/profile/notification-settings/`,
        create: `/user/create/`,
        delete: `/user/delete/`,
        avatar: '/user/avatar/',
        contact_information_update: `/user/contact-information/update/`,

        tasks_archive: {
            master_get: `/master/tasks/archive/`,
            master_delete: `/master/tasks/%d/delete/`,

            customer_get: `/customer/tasks/archive/`,
            customer_delete: `/customer/tasks/%d/delete/`,

            get: `/%s/tasks/archive/`,
            delete: `/%s/tasks/%d/delete/`,
        },
        tasks_kanban: {
            master_get: `/master/tasks/`,
            customer_get: `/customer/tasks/`,

            call_of: `/customer/tasks/%d/call-of/`,
            customer_complete: '/customer/tasks/%d/complete/',
            approve: '/customer/tasks/%d/masters/%d/approve/',

            refuse: '/master/tasks/%d/refuse/',
            master_complete: '/master/tasks/%d/complete/',
            accept: '/master/tasks/%d/accept/',

        },
        notification: {
            master_get: `/master/notifications/`,
            customer_get: `/customer/notifications/`,
            get: `/%s/notifications/`,

            master_update: `/master/update/`,
            customer_update: `/customer/update/`,
            update: `/%s/update/`,
        }
    },
}