import type { Portfolio, UserResponse } from "~/common/interfaces";

export const useUserProfileStore = defineStore('profile', () => {
    const id = ref<number|null>(null);
    const RoleId = ref<number|null>(null);
    const Role = ref('');
    const FullName = ref('');
    const Gender = ref(false);
    const Email = ref('');
    const ImageUrl = ref<string|null>(null)
    const PhoneNumber = ref('')
    const PhoneCountryCode = ref({
        id: 9,
        name: "Antarctica",
        dial_code: "+672",
        code: "AQ",
        flag: "🇦🇶",
        photo: "https://api.yourservice.ae/media/1",
        phone_mask: ""
    })
    const DateOfBirth = ref('')
    const Region: Ref<null | number | string> = ref(2) // Dubai
    const Language = ref('')
    const Languages = ref([])
    const Biography = ref('')
    const AvailableRoles = ref([])
    const newMessages = ref([])
    const Portfolios = ref<Portfolio[]>([])

    function getNewMessagesCount(task_id?: number) {
        if (!task_id) {
            return newMessages.value?.reduce((previousValue: never, currentValue: never) => {
                return previousValue + currentValue.messages_count
            }, 0)
        } else {
            return newMessages.value?.find((item: any) => item.task_id === task_id)?.messages_count;
        }
    }

    function setPortfoliosList(list: Portfolio[]){
        Portfolios.value = list
    }

    const specialisationIds = computed(() => {
        return Portfolios.value.flatMap(item => item?.specifications?.map(spec => spec.id));
    })
    
    function setUserLocation(id: number){
        Region.value = id
    }

    function setUserData(payload: UserResponse){
        /* status: boolean
        message: string
        extra: {} */
        
        
        if(payload?.id) id.value = payload?.id
        if(payload?.role) Role.value = payload?.role
        if(payload?.role_id) RoleId.value = payload?.role_id
        if(payload?.full_name) FullName.value = payload?.full_name
        if(payload?.gender) Gender.value = payload?.gender
        if(payload?.email) Email.value = payload?.email
        if(payload?.image_url) ImageUrl.value = payload?.image_url
        if(payload?.phone_number) PhoneNumber.value = payload?.phone_number
        if(payload?.country_code) PhoneCountryCode.value = payload?.country_code
        if(payload?.date_of_birth) DateOfBirth.value = payload?.date_of_birth
        if(payload?.city) Region.value = payload?.city
        if(payload?.language) Language.value = payload?.language
        if(payload?.languages) Languages.value = payload?.languages
        if(payload?.biography) Biography.value = payload?.biography
        if(payload?.roles) AvailableRoles.value = payload?.roles
        if(payload?.messages) newMessages.value = payload?.messages?.filter((item: any) => item.messages_count > 0);

        /* if(payload?.access) payload?.access
        if(payload?.refresh) payload?.refresh */
    }

    function useClearUserProfile() {
        Role.value = ''
        FullName.value = ''
        Gender.value = false
        Email.value = ''
        ImageUrl.value = null
        PhoneNumber.value = ''
        DateOfBirth.value = ''
        Region.value = 1
        Language.value = ''
        Languages.value = []
        Biography.value = ''
        AvailableRoles.value = []
        newMessages.value = []
        Portfolios.value = []
        PhoneCountryCode.value = {
            id: 9,
            name: "Antarctica",
            dial_code: "+672",
            code: "RU",
            flag: "🇦🇶",
            photo: "https://api.yourservice.ae/media/1",
            phone_mask: ""
        }
    }

    function setRole(role: string){
        Role.value = role
    }

    return {
        id,
        Role,
        RoleId,
        FullName,
        Gender,
        Email,
        ImageUrl,
        PhoneNumber,
        PhoneCountryCode,
        DateOfBirth,
        Region,
        Language,
        Biography,
        Languages,
        Portfolios,
        getNewMessagesCount,
        newMessages,
        AvailableRoles,
        useClearUserProfile,
        setUserLocation,
        setUserData,
        setRole,
        setPortfoliosList,
        specialisationIds
    }
});
