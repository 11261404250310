<script setup lang="ts">
import { useDictionaryStore } from "~/store/dictionary.store"; // Импорт состояния для работы с телефонными кодами из Pinia store

const { locale } = useI18n()

const dir = computed(() => locale.value === 'ar' ? 'rtl' : 'ltr')
const lang = computed(() => locale.value === 'ar' ? 'ar' : 'en')

useHead({
    htmlAttrs: {
        dir: dir,
        lang: lang,
    },
    link: [
        { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
        { rel: 'mask-icon', color: "#000000" },
        { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
    ],
    meta: [
        {
            name: 'theme-color',
            content: '#0C3B2E'
        },
    ]
})

const DictionaryStore = useDictionaryStore(); // Получение состояния телефонных кодов
await DictionaryStore.fetchCodePhones();
await DictionaryStore.fetchSpecifications();
await DictionaryStore.fetchRegions()
await DictionaryStore.fetchCities()

interface AuthCookie {
    access: string;
    refresh: string;
    is_auth: boolean;
}

const authStore = useAuthStore();
const elAuthCookie = useCookie<AuthCookie>('auth');

watch(() => authStore.isAuth, () => {
    elAuthCookie.value = {
        access: authStore.Access,
        refresh: authStore.Refresh,
        is_auth: authStore.isAuth
    }
}, { deep: true });

const isProduction = process.env.NODE_ENV === 'production'

</script>
<template>
    <Histats v-if="isProduction" />
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

<style>
.page-enter-active,
.page-leave-active {
    transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(1rem);
}
</style>