import {getPhoneCodesList, getSpecificationsList, getRegionsList, getCitiesList} from "~/services/dictionary-service";
import { useStatesStore } from '~/store/auth_states.store.js'
import type { DialCode, SpecificationCard, Region, City, Language } from "~/types/dictionary";

export const useDictionaryStore = defineStore({
    id: 'dictionaryStore',

    state: () => ({
        CodePhoneList: [] as DialCode[],
        Specifications: [] as SpecificationCard[],
        Regions: [] as Region[],
        Cities: [] as City[],
        Languages: [] as Language[]
    }),

    getters: {
        allSpecifications: (state) => state.Specifications,
        parentSpecifications: (state) => state.Specifications.filter(item => !item.parent),
        childSpecifications: (state) => (parent_id: number | null = null) => {
                if(!parent_id) return []
                return state.Specifications.filter(item => item.parent === parent_id);
        },

        regions: (state) => state.Regions,
        cities: (state) => state.Cities,
        languages: (state) => state.Languages,
        locations: (state) => {
            return state.Regions.map(region => {
                region.cities = state.Cities.filter(city => city.region === region.id)
                return region
            })
        },
    },

    actions: {
        async fetchCodePhones() {
          if (this.CodePhoneList.length) return;
          try {
            const { data: PhoneCodeList } = await getPhoneCodesList({});
            this.CodePhoneList = PhoneCodeList.value || [];
            const StatesStore = useStatesStore();
            StatesStore.setCurrentDialCode();
          } catch (error) {
            console.error('Error fetch phone codes:', error);
          }
        },
    
        async fetchSpecifications(params = {}) {
          if (this.Specifications.length) return;
          try {
            const { data: SpecificationsList } = await getSpecificationsList(params);
            this.Specifications = SpecificationsList.value || [];
          } catch (error) {
            console.error('Error fetch specifications:', error);
          }
        },
        async fetchRegions() {
          if (this.Regions.length) return;
          try {
            const { data: RegionsList } = await getRegionsList();
            this.Regions = RegionsList.value || [];
          } catch (error) {
            console.error('Error fetch regions:', error);
          }
        },
        async fetchCities() {
          if (this.Cities.length) return;
          try {
            const { data: CitiesList } = await getCitiesList();
            this.Cities = CitiesList.value || [];
          } catch (error) {
            console.error('Error fetch cities:', error);
          }
        },
    },
})