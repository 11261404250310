import { default as indexGnP0twjjrLMeta } from "/app/pages/aboutUs/index.vue?macro=true";
import { default as category_45_91id_938IoS84EI0jMeta } from "/app/pages/catalog/category-[id].vue?macro=true";
import { default as indexvJKqjSbZLzMeta } from "/app/pages/catalog/index.vue?macro=true";
import { default as indexieaNqtTvKQMeta } from "/app/pages/catalog/master-[master_id]/index.vue?macro=true";
import { default as portfolio_45_91id_93w4M441rJUYMeta } from "/app/pages/catalog/master-[master_id]/portfolio-[id].vue?macro=true";
import { default as sub_45category_45_91id_93lgdJWjOEugMeta } from "/app/pages/catalog/sub-category-[id].vue?macro=true";
import { default as coming_45soonp8168ES4xqMeta } from "/app/pages/coming-soon.vue?macro=true";
import { default as indexQ6aaLm6uHyMeta } from "/app/pages/contacts/index.vue?macro=true";
import { default as indexUAMGQig0OAMeta } from "/app/pages/create-account/index.vue?macro=true";
import { default as chatsVSS4e6IWhCMeta } from "/app/pages/customer-office/chats.vue?macro=true";
import { default as indexVgdoxsv6GvMeta } from "/app/pages/customer-office/index.vue?macro=true";
import { default as orders_45archiveEVj7ITeu39Meta } from "/app/pages/customer-office/orders-archive.vue?macro=true";
import { default as indexdxOTKoq6k2Meta } from "/app/pages/customer-office/profile/index.vue?macro=true";
import { default as notification_45settings8g1DKPU5SFMeta } from "/app/pages/customer-office/profile/notification-settings.vue?macro=true";
import { default as privacy_45securityZy6UnyTb87Meta } from "/app/pages/customer-office/profile/privacy-security.vue?macro=true";
import { default as profilemZNwzd5ZrrMeta } from "/app/pages/customer-office/profile.vue?macro=true";
import { default as _91article_93q3qbbdo3uPMeta } from "/app/pages/help/[id]/article/[article].vue?macro=true";
import { default as index89kcWQtMUyMeta } from "/app/pages/help/[id]/index.vue?macro=true";
import { default as indexjpdP4BPFKIMeta } from "/app/pages/help/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as chatsjTXTOJh46IMeta } from "/app/pages/master-office/chats.vue?macro=true";
import { default as indexPzNdXdv9jKMeta } from "/app/pages/master-office/index.vue?macro=true";
import { default as portfoliomoaZtnZiCSMeta } from "/app/pages/master-office/portfolio.vue?macro=true";
import { default as billingHRicchvkV5Meta } from "/app/pages/master-office/profile/billing.vue?macro=true";
import { default as indexPGVBpr34xJMeta } from "/app/pages/master-office/profile/index.vue?macro=true";
import { default as notification_45settingsUyUIzbqidtMeta } from "/app/pages/master-office/profile/notification-settings.vue?macro=true";
import { default as privacy_45securityUPrS9ye6PIMeta } from "/app/pages/master-office/profile/privacy-security.vue?macro=true";
import { default as profileHgy6J17bSdMeta } from "/app/pages/master-office/profile.vue?macro=true";
import { default as promotionhU7cJZwvwxMeta } from "/app/pages/master-office/promotion.vue?macro=true";
import { default as task_45archivex5Gx9AeYf5Meta } from "/app/pages/master-office/task-archive.vue?macro=true";
import { default as _91task_id_93M5iBj4UWv5Meta } from "/app/pages/master-office/task-search/[task_id].vue?macro=true";
import { default as indexsrlcQYEUDEMeta } from "/app/pages/master-office/task-search/index.vue?macro=true";
import { default as order_45createv22DEFCmw9Meta } from "/app/pages/order-create.vue?macro=true";
import { default as indext2mji5fLq5Meta } from "/app/pages/privacy-policy/index.vue?macro=true";
import { default as category_45_91category_id_93_59master_45_91master_id_93_59portfolio_45_91id_93P73mWbrni6Meta } from "/app/pages/tasks/category-[category_id];master-[master_id];portfolio-[id].vue?macro=true";
import { default as category_45_91id_93WAbvjYLccjMeta } from "/app/pages/tasks/category-[id].vue?macro=true";
import { default as sub_45category_45_91id_93C3WlXrj2JuMeta } from "/app/pages/tasks/sub-category-[id].vue?macro=true";
import { default as indexejAi8hALgAMeta } from "/app/pages/terms-of-service/index.vue?macro=true";
export default [
  {
    name: "aboutUs",
    path: "/aboutUs",
    component: () => import("/app/pages/aboutUs/index.vue")
  },
  {
    name: "catalog-category-id",
    path: "/catalog/category-:id()",
    meta: category_45_91id_938IoS84EI0jMeta || {},
    component: () => import("/app/pages/catalog/category-[id].vue")
  },
  {
    name: "catalog",
    path: "/catalog",
    component: () => import("/app/pages/catalog/index.vue")
  },
  {
    name: "catalog-master-master_id",
    path: "/catalog/master-:master_id()",
    component: () => import("/app/pages/catalog/master-[master_id]/index.vue")
  },
  {
    name: "catalog-master-master_id-portfolio-id",
    path: "/catalog/master-:master_id()/portfolio-:id()",
    component: () => import("/app/pages/catalog/master-[master_id]/portfolio-[id].vue")
  },
  {
    name: "catalog-sub-category-id",
    path: "/catalog/sub-category-:id()",
    meta: sub_45category_45_91id_93lgdJWjOEugMeta || {},
    component: () => import("/app/pages/catalog/sub-category-[id].vue")
  },
  {
    name: "coming-soon",
    path: "/coming-soon",
    meta: coming_45soonp8168ES4xqMeta || {},
    component: () => import("/app/pages/coming-soon.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/app/pages/contacts/index.vue")
  },
  {
    name: "create-account",
    path: "/create-account",
    meta: indexUAMGQig0OAMeta || {},
    component: () => import("/app/pages/create-account/index.vue")
  },
  {
    name: "customer-office-chats",
    path: "/customer-office/chats",
    meta: chatsVSS4e6IWhCMeta || {},
    component: () => import("/app/pages/customer-office/chats.vue")
  },
  {
    name: "customer-office",
    path: "/customer-office",
    meta: indexVgdoxsv6GvMeta || {},
    component: () => import("/app/pages/customer-office/index.vue")
  },
  {
    name: "customer-office-orders-archive",
    path: "/customer-office/orders-archive",
    meta: orders_45archiveEVj7ITeu39Meta || {},
    component: () => import("/app/pages/customer-office/orders-archive.vue")
  },
  {
    name: profilemZNwzd5ZrrMeta?.name,
    path: "/customer-office/profile",
    meta: profilemZNwzd5ZrrMeta || {},
    component: () => import("/app/pages/customer-office/profile.vue"),
    children: [
  {
    name: "customer-office-profile",
    path: "",
    meta: indexdxOTKoq6k2Meta || {},
    component: () => import("/app/pages/customer-office/profile/index.vue")
  },
  {
    name: "customer-office-profile-notification-settings",
    path: "notification-settings",
    meta: notification_45settings8g1DKPU5SFMeta || {},
    component: () => import("/app/pages/customer-office/profile/notification-settings.vue")
  },
  {
    name: "customer-office-profile-privacy-security",
    path: "privacy-security",
    meta: privacy_45securityZy6UnyTb87Meta || {},
    component: () => import("/app/pages/customer-office/profile/privacy-security.vue")
  }
]
  },
  {
    name: "help-id-article-article",
    path: "/help/:id()/article/:article()",
    component: () => import("/app/pages/help/[id]/article/[article].vue")
  },
  {
    name: "help-id",
    path: "/help/:id()",
    component: () => import("/app/pages/help/[id]/index.vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/app/pages/help/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "master-office-chats",
    path: "/master-office/chats",
    meta: chatsjTXTOJh46IMeta || {},
    component: () => import("/app/pages/master-office/chats.vue")
  },
  {
    name: "master-office",
    path: "/master-office",
    meta: indexPzNdXdv9jKMeta || {},
    component: () => import("/app/pages/master-office/index.vue")
  },
  {
    name: "master-office-portfolio",
    path: "/master-office/portfolio",
    meta: portfoliomoaZtnZiCSMeta || {},
    component: () => import("/app/pages/master-office/portfolio.vue")
  },
  {
    name: profileHgy6J17bSdMeta?.name,
    path: "/master-office/profile",
    meta: profileHgy6J17bSdMeta || {},
    component: () => import("/app/pages/master-office/profile.vue"),
    children: [
  {
    name: "master-office-profile-billing",
    path: "billing",
    meta: billingHRicchvkV5Meta || {},
    component: () => import("/app/pages/master-office/profile/billing.vue")
  },
  {
    name: "master-office-profile",
    path: "",
    meta: indexPGVBpr34xJMeta || {},
    component: () => import("/app/pages/master-office/profile/index.vue")
  },
  {
    name: "master-office-profile-notification-settings",
    path: "notification-settings",
    meta: notification_45settingsUyUIzbqidtMeta || {},
    component: () => import("/app/pages/master-office/profile/notification-settings.vue")
  },
  {
    name: "master-office-profile-privacy-security",
    path: "privacy-security",
    meta: privacy_45securityUPrS9ye6PIMeta || {},
    component: () => import("/app/pages/master-office/profile/privacy-security.vue")
  }
]
  },
  {
    name: "master-office-promotion",
    path: "/master-office/promotion",
    meta: promotionhU7cJZwvwxMeta || {},
    component: () => import("/app/pages/master-office/promotion.vue")
  },
  {
    name: "master-office-task-archive",
    path: "/master-office/task-archive",
    meta: task_45archivex5Gx9AeYf5Meta || {},
    component: () => import("/app/pages/master-office/task-archive.vue")
  },
  {
    name: "master-office-task-search-task_id",
    path: "/master-office/task-search/:task_id()",
    meta: _91task_id_93M5iBj4UWv5Meta || {},
    component: () => import("/app/pages/master-office/task-search/[task_id].vue")
  },
  {
    name: "master-office-task-search",
    path: "/master-office/task-search",
    meta: indexsrlcQYEUDEMeta || {},
    component: () => import("/app/pages/master-office/task-search/index.vue")
  },
  {
    name: "order-create",
    path: "/order-create",
    meta: order_45createv22DEFCmw9Meta || {},
    component: () => import("/app/pages/order-create.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/app/pages/privacy-policy/index.vue")
  },
  {
    name: "tasks-category-category_id;master-master_id;portfolio-id",
    path: "/tasks/category-:category_id();master-:master_id();portfolio-:id()",
    component: () => import("/app/pages/tasks/category-[category_id];master-[master_id];portfolio-[id].vue")
  },
  {
    name: "tasks-category-id",
    path: "/tasks/category-:id()",
    meta: category_45_91id_93WAbvjYLccjMeta || {},
    component: () => import("/app/pages/tasks/category-[id].vue")
  },
  {
    name: "tasks-sub-category-id",
    path: "/tasks/sub-category-:id()",
    meta: sub_45category_45_91id_93C3WlXrj2JuMeta || {},
    component: () => import("/app/pages/tasks/sub-category-[id].vue")
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    component: () => import("/app/pages/terms-of-service/index.vue")
  }
]